import React, { useState, useEffect } from 'react';
import './App.css'

import {
   Admin,
   Resource,
   CustomRoutes,
   useRedirect
} from 'react-admin'

import {
   ActionsLogsList,
   DefaultGoogleCampaignSettingsList,
   ConversionsReportsList,
   ReportSettingsList,
   ReportSponsoredSettingsList,
   TopBrandsSettingsList,
   UserSettingsList,
   PlatformAccountSettingsList,
   CampaignSettingsList,
   MediaPlatformSettingsList,
   CronSettingsList,
   RedirectDomainsList,
   RedirectLinksList,
   AffiliateSampleRequestsList,
   AffiliateAccessList,
   AdvertiserAccessList,
   MetaApiDataList,
   AggregatedMetaApiDataList,
} from './components/react-admin/lists'

import {
   DefaultGoogleCampaignSettingsEdit,
   ConversionsReportsEdit,
   ReportSettingsEdit,
   ReportSponsoredSettingsEdit,
   TopBrandsSettingsEdit,
   UserSettingsEdit,
   PlatformAccountSettingsEdit,
   MediaPlatformSettingsEdit,
   CampaignSettingsEdit,
   CronSettingsEdit,
   ArticlesSetupEdit,
   AffiliateSampleRequestsEdit,
   AdvertiserAccessEdit,
} from './components/react-admin/edits'

import {
   DefaultGoogleCampaignSettingsCreate,
   ConversionsReportsCreate,
   ReportSettingsCreate,
   ReportSponsoredSettingsCreate,
   TopBrandsSettingsCreate,
   UserSettingsCreate,
   PlatformAccountSettingsCreate,
   MediaPlatformSettingsCreate,
   CampaignSettingsCreate,
   CronSettingsCreate,
   RedirectDomainsCreate,
   AffiliateAccessCreate,
   AdvertiserAccessCreate,
} from './components/react-admin/creates'

import {
   DownloadConversionReportPage,
   VersionHistoryPage,
   UpdateTunePage,
   UploadReportPage,
} from './components/analytics-dashboard'

import { CustomLayout } from './layout/CustomLayout'
import { Route } from 'react-router-dom'
import { lightTheme } from './layout/themes'
import { authProvider, dataProvider } from './config'
import { ReportActionsPage } from './components/analytics-dashboard'
import { ActionsPage } from './components/analytics-dashboard/pages/ActionsPage'
import { NotAllowedPage } from './components/helperComponents/NotAllowedPage'
import { UserIDsBulkUpdatePage } from './components/helperComponents/UserIDsBulkUpdatePage'
import { ArticlesSetupList } from './components/react-admin/redirect-links/articles_setup/ArticlesSetup.List'
import {
   OpenRequestUnit
} from './components/react-admin/sample-system/affiliate-sample-requests/components/OpenRequestUnit'
import { isSessionExpired } from './helpers/authUtils'
import { USER_ROLES } from './config/constants/authConstants';
import { checkAccess } from './helpers/authUtils';

const App = () => {
   const redirect = useRedirect()
   const [scaleFactor, setScaleFactor] = useState(1);
    
   useEffect(() => {
      const scaleOfScreen = window.devicePixelRatio;

      if (scaleOfScreen > 1 && scaleOfScreen < 1.5) {
         setScaleFactor(1.33);
      } else if (scaleOfScreen >= 1.5) {
         setScaleFactor(1.49);
      } else {
         setScaleFactor(1);
      }
   }, []);

   const calculatedHeight = `${100 * scaleFactor}vh`;

   useEffect(() => {
      const handleVisibilityChange = () => {
         if (!document.hidden && isSessionExpired()) {
            redirect('/login');
         }
      };
  
      document.addEventListener('visibilitychange', handleVisibilityChange);
  
      return () => {
         document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
   }, [redirect]);

   return (
      <div className="admin-wrapper" style={{ height: calculatedHeight, overflowY: 'scroll' }}>
         <Admin
            authProvider={authProvider}
            dataProvider={dataProvider}
            dashboard={ActionsPage}
            layout={CustomLayout}
            theme={lightTheme}
         >
            {({role}) => {
               return [
                  <CustomRoutes>
                     <Route path="/not-allowed" element={<NotAllowedPage/>}/>
                     <Route path="/upload-report" element={<UploadReportPage/>}/>
                     <Route path="/report-actions" element={<ReportActionsPage/>}/>
                     <Route path="/download-report" element={<DownloadConversionReportPage/>}/>
                     <Route path="/sample_system/affiliate_sample_requests/edit/:row_id" element={<OpenRequestUnit/>}/>
                     {checkAccess({ role, allowedRoles: [USER_ROLES.ADMIN] })
                        ? <Route path="/provide-access-to-accounts" element={<UserIDsBulkUpdatePage/>}/>
                        : null
                     }
                     {checkAccess({
                        role,
                        allowedRoles: [
                           USER_ROLES.ADMIN,
                           USER_ROLES.MEDIA_BUYER,
                           USER_ROLES.CONTENT_MEDIA_MANAGER,
                           USER_ROLES.MEDIA_MANAGER
                        ]
                     })
                        ? <Route path="/update-tune" element={<UpdateTunePage/>}/>
                        : null
                     }
                     <Route path="/history" element={<VersionHistoryPage/>}/>
                  </CustomRoutes>,

                  <Resource
                     name="action_logs"
                     list={ActionsLogsList}
                  />,

                  checkAccess({ role, allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.CONTENT_MEDIA_MANAGER] }) ?
                     <>
                        <Resource
                           name="articles_setup"
                           list={ArticlesSetupList}
                           edit={ArticlesSetupEdit}
                           create={ArticlesSetupEdit}
                        />
                        <Resource
                           name="redirect_domains"
                           list={RedirectDomainsList}
                           create={RedirectDomainsCreate}
                        />
                        <Resource
                           name="redirect_links"
                           list={RedirectLinksList}
                        />
                     </>
                     :
                     null,

                  checkAccess({
                     role,
                     allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.CONTENT_MEDIA_MANAGER, USER_ROLES.AFFILIATE_MANAGER]
                  }) ?
                     <>
                        <Resource
                           name="sample_system/affiliate_sample_requests"
                           list={AffiliateSampleRequestsList}
                           edit={AffiliateSampleRequestsEdit}
                        />
                        <Resource
                           name="sample_system/affiliate_access"
                           list={AffiliateAccessList}
                           create={AffiliateAccessCreate}
                        />
                        <Resource
                           name="sample_system/advertiser_access"
                           list={AdvertiserAccessList}
                           edit={AdvertiserAccessEdit}
                        />
                     </>
                     :
                     null,

                  checkAccess({ role, allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.MEDIA_BUYER] }) ?
                     <>
                        <Resource
                           name="campaign_settings"
                           list={CampaignSettingsList}
                           edit={CampaignSettingsEdit}
                           create={CampaignSettingsCreate}
                        />

                        <Resource
                           name="default_google_campaign_settings"
                           list={DefaultGoogleCampaignSettingsList}
                           edit={DefaultGoogleCampaignSettingsEdit}
                           create={DefaultGoogleCampaignSettingsCreate}
                        />

                        <Resource
                           name="conversion_reports"
                           list={ConversionsReportsList}
                           edit={ConversionsReportsEdit}
                           create={ConversionsReportsCreate}
                        />

                        <Resource
                           name="report_sponsored_settings"
                           list={ReportSponsoredSettingsList}
                           edit={ReportSponsoredSettingsEdit}
                           create={ReportSponsoredSettingsCreate}
                        />

                        <Resource
                           name="top_brand_settings"
                           list={TopBrandsSettingsList}
                           edit={TopBrandsSettingsEdit}
                           create={TopBrandsSettingsCreate}
                        />
                     </>
                     :
                     null,

                  checkAccess({
                     role,
                     allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.MEDIA_BUYER, USER_ROLES.MEDIA_MANAGER] }) ?
                     <>
                        <Resource
                           name="platform_account_settings"
                           list={PlatformAccountSettingsList}
                           edit={PlatformAccountSettingsEdit}
                           create={PlatformAccountSettingsCreate}
                        />

                        <Resource
                           name="report_settings"
                           list={ReportSettingsList}
                           edit={ReportSettingsEdit}
                           create={ReportSettingsCreate}
                        />
                     </>
                     : null,

                  checkAccess({ role, allowedRoles: [USER_ROLES.ADMIN] }) &&
                  <>
                     <Resource
                        name="user_settings"
                        list={UserSettingsList}
                        edit={UserSettingsEdit}
                        create={UserSettingsCreate}
                     />,

                     <Resource
                        name="cron_settings"
                        list={CronSettingsList}
                        edit={CronSettingsEdit}
                        create={CronSettingsCreate}
                     />
                  </>,

                  checkAccess({ role, allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.MEDIA_MANAGER] }) &&
                  <>
                     <Resource
                        name="media_platform_api_settings"
                        list={MediaPlatformSettingsList}
                        edit={MediaPlatformSettingsEdit}
                        create={MediaPlatformSettingsCreate}
                     />

                     <Resource
                        name="meta_api_data"
                        list={MetaApiDataList}
                     />

                     <Resource
                        name="meta_api_data/aggregated"
                        list={AggregatedMetaApiDataList}
                     />

                     <Resource
                        name="meta_api_data/aggregated-pixel"
                        list={AggregatedMetaApiDataList}
                     />
                  </>
               ]
            }}
         </Admin>
      </div>
   )
}

   export default App
