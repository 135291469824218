import { useEffect, useState } from 'react';
import {
  DatagridConfigurable,
  List,
  TextField,
  ReferenceField,
  SelectColumnsButton
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { parse } from 'query-string';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { DocumentationButton } from '../../../helperComponents/DocumentationButton';
import { EmptyBulkActionButtons } from '../../../helperComponents/EmptyBulkActionButtons';
import { AggregateBySelect } from '../../../helperComponents/AggregateBySelect';
import { ActionsWithoutCreate } from '../../settings/cron_settings/helpers/ActionsWithoutCreate';
import { useRowStyle } from '../../../../hooks/useRowStyle';
import { getMetaApiDataAggregatedFilters } from '../../../../utils/filters/metaApiDataFilters';
import { fetchAccounts } from '../../../../utils/accountUtils';
import { MetaApiData, tableAggregationSettings } from '../../../../helpers/constants';

export const AggregatedMetaApiDataList = (props) => {
  const rowClassName = useRowStyle();
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);

  const handleRowClick = (id, basePath, record) => {
    const queryParams = parse(window.location.search);
    const currentFilters = queryParams.filter ? JSON.parse(queryParams.filter) : {};
    const aggregateValue = localStorage.getItem('aggregate') || tableAggregationSettings['meta_api_data'].defaultValue;
    const newFilters = { ...currentFilters, [aggregateValue]: record[aggregateValue] };

    navigate({
      pathname: '/meta_api_data',
      search: `?filter=${encodeURIComponent(JSON.stringify(newFilters))}`,
    });
  };

  useEffect(() => {
    const loadAccounts = async () => {
      const fetchedAccounts = await fetchAccounts();
      setAccounts(fetchedAccounts);
    };

    loadAccounts();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-end">
        <TitleAboveTable title="Meta API Data" />
        <DocumentationButton
          reference="meta-api-documentation"
          label="Meta Data API Documentation"
        />
      </div>
      <List
        {...props}
        title=" "
        actions={<ActionsWithoutCreate
          AdditionalButton={
            <>
              <AggregateBySelect tableName='meta_api_data' />
              <SelectColumnsButton preferenceKey="meta_api_data.datagrid" />
            </>
          }
        />}
        pagination={<TablePagination />}
        perPage={25}
        filters={getMetaApiDataAggregatedFilters(accounts)}
      >
        <DatagridConfigurable
          rowClick={handleRowClick}
          bulkActionButtons={<EmptyBulkActionButtons/>}
          preferenceKey="meta_api_data.datagrid"
          omit={MetaApiData.defaultOffColumns}
          className={`${rowClassName} datagrid-content`}
        >
          <TextField source='count' label="Count" />
          <TextField source="data_source" label="Data Source" />
          <TextField source="settings_id" label="Settings ID" />
          <TextField source="pixel_id" label="FB Pixel ID" />
          <TextField source="brand_id" label="Brand ID" />
          <TextField source="order_id" label="Order ID" />
          <TextField source="fb_event_id" label="Event ID" />
          <TextField label="Internal Account ID" />
          <TextField label="Account ID" />
          <ReferenceField
            source="internal_account_id"
            reference="reference_fields/platform_account_info"
            label="Account Name"
          >
            <TextField source="account_name" />
          </ReferenceField>
          <TextField label="Campaign ID" />
          <TextField label="Transaction ID" />
          <TextField label="Event Name" />
          <TextField label="Value" />
          <TextField label="Payout Percentage" />
          <TextField label="Event Time" />
          <TextField label="API Response" source="api_response" />
        </DatagridConfigurable>
      </List>
    </>
  );
};
