import { httpClient } from '../config';

export const requestReportDownload = async (endpoint, requestBody) => {
  try {
    const response = await httpClient(endpoint, {
      method: 'POST',
      body: JSON.stringify(requestBody),
    });

    return response;
  } catch (error) {
    console.error('Error performing report download request:', error);
    throw error;
  }
};