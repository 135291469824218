import { requestReportDownload } from './loaders';
import { RAW_DATA_STATUSES, RAW_DATA_ENDPOINTS } from '../config/constants';

export const generateReport = async (platform, type, setStatus) => {
  const requestBody = { platform, ...(type && { type }) };

  try {
    const response = await requestReportDownload(RAW_DATA_ENDPOINTS.GENERATE, requestBody);
    const { status } = JSON.parse(response.body);
    setStatus(status);
  } catch (error) {
    console.error('Error on form submission:', error);
  }
};

const constructDownloadUrl = (platform, type) => {
  let downloadUrl = `${RAW_DATA_ENDPOINTS.DOWNLOAD}?platform=${encodeURIComponent(platform)}`;
  if (type) {
    downloadUrl += `&type=${encodeURIComponent(type)}`;
  }
  return downloadUrl;
};

export const initiateDownload = (platform, type, setStatus) => {
  const downloadUrl = constructDownloadUrl(platform, type);
  window.location.href = downloadUrl;
  setStatus(RAW_DATA_STATUSES.NOT_FOUND);
};
