import { useEffect, useState } from 'react';
import {
  List,
  SelectColumnsButton,
} from 'react-admin';
import { useNavigate, useLocation } from 'react-router-dom';
import { DocumentationButton } from '../../../helperComponents/DocumentationButton';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { AggregateBySelect } from '../../../helperComponents/AggregateBySelect';
import { ActionsWithoutCreate } from '../../settings/cron_settings/helpers/ActionsWithoutCreate';
import { handleRedirectionBasedOnFilters, getTableAggregationPath } from '../../../../utils/aggregatedTablesUtils';
import { getMetaApiDataFilters } from '../../../../utils/filters/metaApiDataFilters';
import { fetchAccounts } from '../../../../utils/accountUtils';
import { tableAggregationSettings } from '../../../../helpers/constants';
import { MetaApiDataGrid } from './MetaApiDataGrid';

export const MetaApiDataList = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    handleRedirectionBasedOnFilters({
      location,
      navigate,
      path: `/${getTableAggregationPath('meta_api_data')}`,
      aggregateField: localStorage.getItem('aggregate') || tableAggregationSettings['meta_api_data'].defaultValue
    });
  }, [location.search, navigate]);

  useEffect(() => {
    const loadAccounts = async () => {
      const fetchedAccounts = await fetchAccounts();
      setAccounts(fetchedAccounts);
    };

    loadAccounts();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-end">
        <TitleAboveTable title="Meta API Data" />
        <DocumentationButton
          reference="meta-api-documentation"
          label="Meta Data API Documentation"
        />
      </div>
      <List
        {...props}
        title=" "
        actions={<ActionsWithoutCreate
          AdditionalButton={
            <>
              <AggregateBySelect tableName='meta_api_data' />
              <SelectColumnsButton preferenceKey="meta_api_data.datagrid" />
            </>
          }
        />}
        pagination={<TablePagination />}
        perPage={25}
        filters={getMetaApiDataFilters(accounts)}
        sort={{ field: 'event_time', order: 'DESC' }}
      >
        <MetaApiDataGrid />
      </List>
    </>
  );
};
