import { useNavigate } from 'react-router-dom';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { tableAggregationSettings } from '../../helpers/constants';
import { getAggregateValue, handleAggregateChange } from '../../utils/aggregatedTablesUtils';

export const AggregateBySelect = ({ tableName }) => {
  const navigate = useNavigate();

  return (
    <FormControl
      variant="outlined"
      size="small"
      style={{ margin: '0 10px', width: '280px' }}
    >
      <InputLabel htmlFor="aggregate-by">Aggregate by</InputLabel>
      <Select
        label="Aggregate by"
        value={getAggregateValue(tableName)}
        onChange={(event) => handleAggregateChange({ selectedValue: event.target.value, navigate, tableName })}
        id="aggregate-by"
      >
        {tableAggregationSettings[tableName]?.options?.map(item => (
          <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
