import {
   TopToolbar,
   ExportButton,
   FilterButton, useTheme,
} from 'react-admin'

import { Button, Tooltip } from '@mui/material'
import { darkTheme, lightTheme } from '../../../../../layout/themes'
import { ReorderRounded } from '@mui/icons-material'


export const ActionsWithoutCreate = ({AdditionalButton}) => {
   const [theme, setTheme] = useTheme()

   return (
      <TopToolbar>
         <Button
            onClick={() => {
               const value = !localStorage.getItem('RaStore.isRowStyleActive') ? '1' : ''
               localStorage.setItem('RaStore.isRowStyleActive', value)
               if (theme?.palette.mode === 'dark') {
                  setTheme({...darkTheme, isRowStyleActive: value})
               } else {
                  setTheme({...lightTheme, isRowStyleActive: value})
               }

            }}
         >
            <Tooltip title="Color every second row" placement="bottom">
               <ReorderRounded color="action" fontSize="small"/>
            </Tooltip>
         </Button>
         {AdditionalButton}
         <FilterButton/>
         <ExportButton/>
      </TopToolbar>
   )
}