import { useEffect, useState } from 'react';
import {
  DatagridConfigurable,
  TextField,
  ReferenceField,
  FunctionField,
  ReferenceOneField,
  useListContext
} from 'react-admin';
import { Tooltip } from '@mui/material';
import { DateInOneRow } from '../../../helperComponents/DateInOneRow';
import { EmptyBulkActionButtons } from '../../../helperComponents/EmptyBulkActionButtons';
import { useRowStyle } from '../../../../hooks/useRowStyle';
import { MetaApiData } from '../../../../helpers/constants';
import { findDataSourceName } from '../../../../utils/apiUtils';
import { formatJson } from '../../../../utils/jsonUtils';
import { httpClient } from '../../../../config/httpClient';

export const MetaApiDataGrid = () => {
  const rowClassName = useRowStyle();

  return (
    <DatagridConfigurable
      bulkActionButtons={<EmptyBulkActionButtons />}
      preferenceKey="meta_api_data.datagrid"
      omit={MetaApiData.defaultOffColumns}
      className={`${rowClassName} datagrid-content`}
    >
      <TextField source="id" label="ID" />
      <FunctionField
        label="Data Source"
        render={record => findDataSourceName(record.data_source)}
      />
      <TextField source="settings_id" label="Settings ID" />
      <TextField source="pixel_id" label="FB Pixel ID" />
      <TextField source="brand_id" label="Brand ID" />
      <TextField source="order_id" label="Order ID" />
      <TextField source="fb_event_id" label="Event ID" />
      <TextField source="internal_account_id" label="Internal Account ID" />
      <ReferenceField
        source="internal_account_id"
        reference="reference_fields/platform_account_info"
        label="Account ID"
      >
        <TextField source="account_id" />
      </ReferenceField>
      <ReferenceField
        source="internal_account_id"
        reference="reference_fields/platform_account_info"
        label="Account Name"
      >
        <TextField source="account_name" />
      </ReferenceField>
      <TextField source="campaign_id" label="Campaign ID" />
      <TextField source="transaction_id" label="Transaction ID" />
      <TextField source="event_name" label="Event Name" />
      <TextField source="value" label="Value" />
      <TextField source="payout_percentage" label="Payout Percentage" />
      <DateInOneRow
        source="event_time"
        label="Event Time"
        showTime={true}
      />
      <FunctionField
        label="API Response"
        source="api_response"
        render={record => (
          <Tooltip
            title={
              <pre style={{ maxHeight: '600px', overflowY: 'auto' }}>
                {formatJson(record.api_response)}
              </pre>
            }
            placement='left'
            arrow
            classes={{ popper: 'custom-tooltip' }}
          >
            <div style={{
              width: '280px',
              wordWrap: 'break-word',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
              {record.api_response}
            </div>
          </Tooltip>
        )}
      />
    </DatagridConfigurable>
  );
};
