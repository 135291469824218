import { parse } from 'query-string';
import { tableAggregationSettings } from '../helpers/constants';

export const checkValueIsValid = (options, value) => options.some(option => option.value === value);

export const getAggregateValue = (tableName) => {
  const localStorageValue = localStorage.getItem('aggregate');
  const isValueValid = checkValueIsValid(tableAggregationSettings[tableName].options, localStorageValue);
  return isValueValid ? localStorageValue : tableAggregationSettings[tableName].defaultValue;
};

export const setLocalStorageAggregate = (selectedValue) => {
  localStorage.setItem('aggregate', selectedValue);
};

export const navigateToAggregatePath = ({ navigate, tableName, selectedValue }) => {
  const path = tableAggregationSettings[tableName]?.options.find(option => option.value === selectedValue)?.path;
  navigate(`/${path}`);
};

export const handleAggregateChange = ({ selectedValue, navigate, tableName }) => {
  setLocalStorageAggregate(selectedValue);
  navigateToAggregatePath({ navigate, tableName, selectedValue });
};

export const handleRedirectionBasedOnFilters = ({ location, navigate, path, aggregateField }) => {
  const queryParams = parse(location.search);
  const filters = queryParams.filter ? JSON.parse(queryParams.filter) : {};
  const aggregateValue = localStorage.getItem('aggregate') || '';

  if (shouldRedirect({ filters, aggregateValue, aggregateField })) {
    navigate({
      pathname: path,
      search: `?filter=${encodeURIComponent(JSON.stringify(filters))}`,
    });
  }
}

export const shouldRedirect = ({ filters, aggregateValue, aggregateField }) => {
  return !filters[aggregateField] && aggregateValue !== 'none';
}

export const getTableAggregationPath = (tableName) => {
  const aggregateValue = localStorage.getItem('aggregate') || tableAggregationSettings[tableName].defaultValue;
  const aggregationOption = tableAggregationSettings[tableName].options.find(option => option.value === aggregateValue);
  return aggregationOption.path;
}
